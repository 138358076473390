import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'

export default class AlertInfos {
  constructor() {
    this.DOM = {
      container: document.getElementById('AlertInfos'),
      tarteAuCitron: document.getElementById('tarteaucitronRoot')
    }

    if (!this.DOM.container) return

    if (this.DOM.container) {
      this.DOM.close = this.DOM.container.querySelector('.AlertInfos__close')
    }

    if (this.DOM.tarteAuCitron) {
      this.DOM.tarteAuCitronAllow = this.DOM.tarteAuCitron.querySelector('#tarteaucitronPersonalize2')
      this.DOM.tarteAuCitronDeny = this.DOM.tarteAuCitron.querySelector('#tarteaucitronAllDenied2')
    }

    this.setAlertInfos()
  }

  setAlertInfos() {
    const { container, close, tarteAuCitron, tarteAuCitronAllow, tarteAuCitronDeny } = this.DOM

    if (!tarteAuCitron || !tarteAuCitron.classList.contains('tarteaucitronBeforeVisible')) {
      container.classList.remove('--over-cookies')
    }

    if (tarteAuCitronAllow) tarteAuCitronAllow.addEventListener('click', () => container.classList.remove('--over-cookies'))
    if (tarteAuCitronDeny) tarteAuCitronDeny.addEventListener('click', () => container.classList.remove('--over-cookies'))
    if (close) close.addEventListener('click', this.closeAlertInfos.bind(this))

    this.tlIn = gsap
      .timeline({ delay: 2.5 })
      .to(container, { clipPath: 'inset(0% 0% 0% 0%)', duration: 1, ease: 'expo.inOut' }, 0)
      .fromTo(close, { opacity: 0 }, { opacity: 1, ease: 'power2.inOut' }, '-=0.3')
  }

  closeAlertInfos(e) {
    const { container } = this.DOM

    e.preventDefault()

    this.tlOut = gsap
      .timeline({
        onComplete: () => domStorage.body.removeChild(container)
      })
      .to(container, { clipPath: 'inset(0% 100% 0% 0%)', duration: 0.7, ease: 'expo.inOut' }, 0)
  }
}
